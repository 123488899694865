export default [
    {
        path: '/products/list',
        name: 'apps-products-list',
        props: { module: 'products' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Products',
            redirectIfLoggedIn: false,
            module: 'product',
        },
        component: () => import('@/views/product/ProductsList.vue'),
    },
    {
        path: '/products/view/:id',
        name: 'apps-products-view',
        props: { module: 'products' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Products',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Products',
                    to: '/products/list',
                },
                {
                    text: 'action.View',
                    title: '',
                    active: true,
                },
            ],
            module: 'product',
        },
        component: () => import('@/views/product/ProductsEdit.vue'),
    },
    {
        path: '/products/add',
        name: 'apps-products-add',
        props: { module: 'products' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Products',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Products',
                    to: '/products/list',
                },
                {
                    text: 'action.Add',
                    title: '',
                    active: true,
                },
            ],
            module: 'product',
        },
        component: () => import('@/views/product/ProductsEdit.vue'),
    },
    {
        path: '/products/import',
        name: 'apps-products-import',
        props: { module: 'products' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Products',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Products',
                    to: '/products/import',
                },
                {
                    text: 'Import',
                    active: true,
                },
            ],
            module: 'product',
        },
        component: () => import('@/views/product/ProductsImport.vue'),
    },
    {
        path: '/products/edit/:id',
        name: 'apps-products-edit',
        props: { module: 'products' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Products',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Products',
                    to: '/products/list',
                },
                {
                    text: 'action.Edit',
                    title: '',
                    active: true,
                },
            ],
            module: 'product',
        },
        component: () => import('@/views/product/ProductsEdit.vue'),
    },
    {
        path: '/products/gallery/:id',
        name: 'apps-products-gallery',
        props: { module: 'products' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Products Gallery',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Products',
                    to: '/products/list',
                },
                {
                    text: 'Gallery',
                    to: '/products/edit',
                    to_fix: '/products/edit',
                },
                {
                    text: 'action.Edit',
                    title: '',
                    active: true,
                },
            ],
            module: 'product',
        },
        component: () => import('@/views/product/ProductsGallery.vue'),
    },
]
