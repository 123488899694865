export default {
    loginEndpoint: '/jwt/login',
    registerEndpoint: '/jwt/register',
    refreshEndpoint: '/jwt/refresh-token',
    logoutEndpoint: '/jwt/logout',
    tokenType: 'Bearer',
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken',
    storageImpersonationTokenKeyName: 'accessImpersonationToken',
}
