export default [
    {
        path: '/shortlinks/list',
        name: 'apps-shortlinks-list',
        props: { module: 'shortlinks' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Shortlinks',
            redirectIfLoggedIn: false,
            module: 'shortlinks',
        },
        component: () => import('@/views/shortlinks/ShortlinksList.vue'),
    },
    {
        path: '/shortlinks/view/:id',
        name: 'apps-shortlinks-view',
        props: { module: 'shortlinks' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Shortlinks',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Shortlinks',
                    to: '/shortlinks/list',
                },
                {
                    text: 'action.View',
                    title: '',
                    active: true,
                },
            ],
            module: 'shortlinks',
        },
        component: () => import('@/views/shortlinks/ShortlinksEdit.vue'),
    },
    {
        path: '/shortlinks/add',
        name: 'apps-shortlinks-add',
        props: { module: 'shortlinks' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Shortlinks',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Shortlinks',
                    to: '/shortlinks/list',
                },
                {
                    text: 'action.Add',
                    title: '',
                    active: true,
                },
            ],
            module: 'shortlinks',
        },
        component: () => import('@/views/shortlinks/ShortlinksEdit.vue'),
    },
    {
        path: '/shortlinks/edit/:id',
        name: 'apps-shortlinks-edit',
        props: { module: 'shortlinks' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Shortlinks',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Shortlinks',
                    to: '/shortlinks/list',
                },
                {
                    text: 'action.Edit',
                    title: '',
                    active: true,
                },
            ],
            module: 'shortlinks',
        },
        component: () => import('@/views/shortlinks/ShortlinksEdit.vue'),
    },
    {
        path: '/qr_generator/list',
        name: 'apps-qr-generator-list',
        props: { module: 'qr-generator' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Link/QR Generator',
            redirectIfLoggedIn: false,
            module: 'qr_generator',
        },
        component: () => import('@/views/shortlinks/QrGeneratorList.vue'),
    },
    {
        path: '/qr_generator/add',
        name: 'apps-qr-generator-add',
        props: { module: 'qr-generator' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Link/QR Generator',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Link/QR Generator',
                    to: '/qr_generator/list',
                },
                {
                    text: 'action.Add',
                    title: '',
                    active: true,
                },
            ],
            module: 'qr_generator',
        },
        component: () => import('@/views/shortlinks/QrGeneratorEdit.vue'),
    },
    {
        path: '/qr_generator/edit/:id',
        name: 'apps-qr-generator-edit',
        props: { module: 'qr-generator' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Link/QR Generator',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Link/QR Generator',
                    to: '/qr_generator/list',
                },
                {
                    text: 'action.Edit',
                    title: '',
                    active: true,
                },
            ],
            module: 'qr_generator',
        },
        component: () => import('@/views/shortlinks/QrGeneratorEdit.vue'),
    },
    {
        path: '/c_:code',
        name: 'apps-goto-shortlink',
        props: { module: 'auth' },
        component: () => import('@/views/authentication/GoToShortLink.vue'),
        meta: {
            resource: 'Auth',
            layout: 'full',
            redirectIfLoggedIn: true,
        },
    },
]
