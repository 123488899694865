export default [
    {
        path: '/marketplace',
        name: 'apps-campaigns-products-list',
        props: { module: 'campaigns-products-list' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Marketplace',
            contentRenderer: 'sidebar-left-detached',
            contentClass: 'ecommerce-application',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Marketplace',
                },
                {
                    text: 'action.List',
                    title: '',
                    active: true,
                },
            ],
            module: 'campaign_product',
        },
        component: () => import('@/views/campaigns_products/CampaignProductList.vue'),
    },
    {
        path: '/marketplace/wishlist',
        name: 'apps-campaigns-products-wishlist',
        props: { module: 'campaigns-products-wishlist' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Marketplace Wishlist',
            contentClass: 'ecommerce-application',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Marketplace',
                },
                {
                    text: 'Wishlist',
                    title: '',
                    active: true,
                },
            ],
            module: 'campaign_product',
        },
        component: () => import('@/views/campaigns_products/CampaignProductWishList.vue'),
    },
    {
        path: '/marketplace/view/:id',
        name: 'apps-campaigns-products-view',
        props: { module: 'campaigns-products-list' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Marketplace',
            contentClass: '',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Marketplace',
                    to: '/marketplace',
                },
                {
                    text: 'action.View',
                    title: '',
                    active: true,
                },
            ],
            module: 'campaign_product',
        },
        component: () => import('@/views/campaigns_products/CampaignProductDetails.vue'),
    },
    {
        path: '/collaborations',
        name: 'apps-campaigns-carts',
        props: { module: 'campaigns-carts' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Collaborations',
            redirectIfLoggedIn: false,
            module: 'campaign_product',
        },
        component: () => import('@/views/campaigns_products/CampaignsCartsList.vue'),
    },
    {
        path: '/creator/marketplace/search',
        name: 'apps-marketplace-search',
        props: { module: 'marketplace' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Marketplace',
            contentClass: 'ecommerce-application',
            redirectIfLoggedIn: false,
            module: 'marketplace',
        },
        component: () => import('@/views/marketplace/MarketplaceSearch.vue'),
    },
    {
        path: '/applications/:id?/:status?',
        name: 'apps-applications-list',
        props: { module: 'proposal-request' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Requests',
            redirectIfLoggedIn: false,
            module: 'proposal_request',
        },
        component: () => import('@/views/campaigns_products/ApplicationsList.vue'),
    },
    {
        path: '/campaign_invites/:id?/:status?',
        name: 'apps-campaign-invites-list',
        props: { module: 'campaign-invites' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Invites',
            redirectIfLoggedIn: false,
            module: 'campaigns',
        },
        component: () => import('@/views/campaigns_products/CampaignInvitesList.vue'),
    },
    {
        path: '/report/traffic',
        name: 'apps-campaign-click-report-list',
        props: { module: 'campaign-click-report' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Traffic',
            redirectIfLoggedIn: false,
            module: 'click_report',
        },
        component: () => import('@/views/report/CampaignsClickReportList.vue'),
    },
    {
        path: '/report/payouts',
        name: 'apps-payout-report',
        props: { module: 'payout-report' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Payouts',
            redirectIfLoggedIn: false,
            module: 'order_report',
        },
        component: () => import('@/views/report/PayoutReport.vue'),
    },
    {
        path: '/report/referral-earnings',
        name: 'apps-referral-earnings-report',
        props: { module: 'referral-earnings-report' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Referral Earnings',
            redirectIfLoggedIn: false,
            module: 'order_report',
        },
        component: () => import('@/views/report/ReferralEarningsReport.vue'),
    },
    {
        path: '/report/affiliate-sales',
        name: 'apps-affiliate-sales-report',
        props: { module: 'affiliate-sales-report' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Affiliate Sales',
            redirectIfLoggedIn: false,
            module: 'click_report',
        },
        component: () => import('@/views/report/AffiliateSalesReport.vue'),
    },
    {
        path: '/report/payout-details/:id',
        name: 'apps-payout-report-details',
        props: { module: 'payout-report' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Payouts',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Payouts',
                    to: '/report/payouts',
                },
                {
                    text: '',
                    title: '',
                    active: true,
                },
            ],
            module: 'order_report',
        },
        component: () => import('@/views/report/PayoutReportDetails.vue'),
    },
    {
        path: '/collaborations-list/:id?',
        name: 'apps-requests',
        props: { module: 'campaign-participants' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Requests',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Campaigns',
                    to: '/campaigns/list',
                },
                {
                    text: 'Collaborations',
                    title: '',
                    active: true,
                },
            ],
            module: 'campaigns',
        },
        component: () => import('@/views/report/CollaborationsList.vue'),
    },
    {
        path: '/report/campaign-analysis/:cid?',
        name: 'apps-campaign-analysis',
        props: { module: 'campaign-analysis' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Campaign Analysis',
            redirectIfLoggedIn: false,
            module: 'campaigns',
        },
        component: () => import('@/views/report/CampaignAnalysis.vue'),
    },
    {
        path: '/networks',
        name: 'apps-network-tree-report',
        props: { module: 'network-tree-report' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Networks',
            redirectIfLoggedIn: false,
            module: 'network_tree',
        },
        component: () => import('@/views/report/NetworkTreeReport.vue'),
    },
    {
        path: '/timeline/:id',
        name: 'apps-timeline-view',
        props: { module: 'campaigns-carts' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Timeline',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Collaborations',
                    to: '/collaborations',
                },
                {
                    text: 'Timeline',
                },
                {
                    text: '',
                    title: '',
                    active: true,
                },
            ],
            module: 'timeline',
        },
        component: () => import('@/views/timeline/CreatorTimeline.vue'),
    },
    {
        path: '/brand-timeline/:id',
        name: 'apps-brand-timeline-view',
        props: { module: 'campaign-participants' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Timeline',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Collaborations',
                    to: '/collaborations-list',
                },
                {
                    text: 'Timeline',
                },
                {
                    text: 'Timeline',
                    title: '',
                    active: true,
                },
            ],
            module: 'timeline',
        },

        component: () => import('@/views/timeline/BrandTimeline.vue'),
    },
    {
        path: '/content-list/:id?',
        name: 'apps-content-list',
        props: { module: 'content' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Content Requests',
            redirectIfLoggedIn: false,
            module: 'content_request',
        },
        component: () => import('@/views/timeline/UploadedContentList.vue'),
    },
    {
        path: '/report/agency-requests',
        name: 'apps-agency-request-list',
        props: { module: 'agency-request-list' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Agency Requests',
            redirectIfLoggedIn: false,
            module: 'agency_request',
        },
        component: () => import('@/views/settings/AgencyRequestList.vue'),
    },
    {
        path: '/proposals/list',
        name: 'apps-proposals-list',
        props: { module: 'proposals' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Proposals',
            redirectIfLoggedIn: false,
            module: 'proposals',
        },
        component: () => import('@/views/proposals/ProposalsList.vue'),
    },
    {
        path: '/proposals/details/:id',
        name: 'apps-proposals-details',
        props: { module: 'proposals' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Proposals Details',
            redirectIfLoggedIn: false,
            contentClass: 'ecommerce-application',
            breadcrumb: [
                {
                    text: 'Proposals',
                    to: '/proposals/list',
                },
                {
                    text: '',
                    active: true,
                },
            ],
            module: 'proposals',
        },
        component: () => import('@/views/proposals/ProposalsDetails.vue'),
    },
]
