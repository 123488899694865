import { render, staticRenderFns } from "./ScrollToTop.vue?vue&type=template&id=44715c53&scoped=true"
import script from "./ScrollToTop.vue?vue&type=script&lang=js"
export * from "./ScrollToTop.vue?vue&type=script&lang=js"
import style0 from "./ScrollToTop.vue?vue&type=style&index=0&id=44715c53&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44715c53",
  null
  
)

export default component.exports