const state = {
    storefront: [],
    storefronts: [],
    isBlockToSelectGlobalStorefont: false,
    allowToSelectStorefront: false,
}

const getters = {
    getStorefront: state => state.storefront,
    getIsBlockToSelectStorefront: state => state.isBlockToSelectGlobalStorefont,
    getAllowToSelectStorefront: state => state.allowToSelectStorefront,
}

const mutations = {
    setStorefront: (state, storefront) => {
        state.storefront = storefront
    },
    updateStorefront: (state, storefrontId) => {
        if (state.isBlockToSelectGlobalStorefont) {
            state.storefront = state.storefronts.find((item) => item.id == storefrontId)
            state.tempStorefront = state.storefront
        }
    },
    setIsBlockToSelectStorefont: (state, status) => {
        state.isBlockToSelectGlobalStorefont = status

        if (status && state.storefront) {
            state.allowToSelectStorefront = state.storefront.id == 0
        }
    },
}

export default {
    state,
    getters,
    mutations,
}
