export default [
    {
        path: '/dashboard',
        name: 'dashboard',
        props: { module: 'dashboard' },
        component: () => import('@/views/dashboard/MainDashboard.vue'),
        meta: {
            resource: 'NoAuth',
            redirectIfLoggedIn: false,
            pageTitle: 'Dashboard',
            module: 'dashboard',
        },
    },
]
