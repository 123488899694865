const state = {
    creatorsMarketplace: [],
    creatorsMarketplaceNextPage: [],
}

const getters = {
    getMarketplaceCreators: state => state.creatorsMarketplace,
    getMarketplaceCreatorsNextPage: state => state.creatorsMarketplaceNextPage,
}

const mutations = {
    setMarketplaceCreators: (state, creatorsMarketplace) => {
        state.creatorsMarketplace = creatorsMarketplace
    },
    setMarketplaceCreatorsNextPage: (state, creatorsMarketplace) => {
        state.creatorsMarketplaceNextPage = creatorsMarketplace
    },
    updateMarketplaceCreators: (state, creatorsMarketplaceId) => {
        const index = state.creatorsMarketplace.findIndex(item => parseInt(item.userId, 10) === parseInt(creatorsMarketplaceId, 10))
        if (index !== -1) {
            state.creatorsMarketplace[index].inShortlist = true
        }
    },
    addMediaItemForMarketplaceCreator: (state, data) => {
        const index = state.creatorsMarketplace.findIndex(item => item.username === data.username)
        if (index !== -1) {
            state.creatorsMarketplace[index].media = data.data.media
            state.creatorsMarketplace[index].bio = data.data.bio
            state.creatorsMarketplace[index].verified = data.data.verified
            state.creatorsMarketplace[index].averageViews = data.data.averageViews
        }
    },
    removeCreatorsWhoseMediaNotBeenUploaded: (state, username) => {
        state.creatorsMarketplace = state.creatorsMarketplace.filter(item => item.username !== username)
    },
}

export default {
    state,
    getters,
    mutations,
}
