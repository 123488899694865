export default [
    {
        path: '/error-404',
        name: 'error-404',
        props: { module: 'error' },
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            resource: 'NoAuth',
            layout: 'full',
            redirectIfLoggedIn: false,
            action: 'read',
        },
    },
    {
        path: '/not-authorized',
        name: 'not-authorized',
        props: { module: 'error' },
        component: () => import('@/views/error/NotAuthorized.vue'),
        meta: {
            resource: 'NoAuth',
            layout: 'full',
            redirectIfLoggedIn: false,
            action: 'read',
        },
    },
    {
        path: '/signin',
        name: 'auth-login',
        props: { module: 'auth' },
        component: () => import('@/views/authentication/SignIn.vue'),
        meta: {
            resource: 'Auth',
            layout: 'full',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/signup/:id?',
        name: 'auth-register',
        props: { module: 'auth' },
        component: () => import('@/views/authentication/SignUp.vue'),
        meta: {
            resource: 'Auth',
            layout: 'full',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/forgot-password',
        name: 'auth-forgot-password',
        props: { module: 'auth' },
        component: () => import('@/views/authentication/ForgotPassword.vue'),
        meta: {
            resource: 'Auth',
            layout: 'full',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/password/reset/:token',
        name: 'auth-reset-password',
        props: { module: 'auth' },
        component: () => import('@/views/authentication/ResetPassword.vue'),
        meta: {
            resource: 'Auth',
            layout: 'full',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/verifyAccount/:token',
        name: 'verify_account',
        props: { module: 'auth' },
        component: () => import('@/views/authentication/VerifyAccount.vue'),
        meta: {
            resource: 'Auth',
            layout: 'full',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/sellercentral-authorize',
        name: 'apps-sellercentral-authorize',
        props: { module: 'sellercentral-authorize' },
        component: () => import('@/views/settings/SellercentralAuthorize.vue'),
        meta: {
            resource: 'Any',
            layout: 'full',
            redirectIfLoggedIn: false,
            action: 'read',
            module: 'settings',
        },
    },
    {
        path: '/alert/verify_email',
        name: 'apps-verify-email',
        props: { module: 'auth' },
        component: () => import('@/views/authentication/VerifyEmailAlert.vue'),
        meta: {
            resource: 'Auth',
            layout: 'full',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/gmail/authorize',
        name: 'apps-gmail-authorize',
        props: { module: 'email' },
        component: () => import('@/views/email/EmailList.vue'),
        meta: {
            resource: 'Any',
            module: 'email',
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            redirectIfLoggedIn: false,
            action: 'read',
        },
    },
]
