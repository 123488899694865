export default [
    {
        path: '/ai-settings',
        name: 'apps-ai-avatars',
        props: { module: 'ai-avatars' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'AI Videos',
            redirectIfLoggedIn: false,
            module: 'ai_avatars',
        },
        component: () => import('@/views/ai/AiSettings.vue'),
    },
]
