import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import campaigns from './modules/campaigns'
import shortlists from './modules/shortlists'
import storefront from './modules/storefront'
import marketplace from './modules/marketplace'
import profile from './modules/profile'
import creatorsForCampaign from './modules/creators-for-campaign'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        campaigns,
        shortlists,
        storefront,
        marketplace,
        creatorsForCampaign,
        profile,
    },
    strict: process.env.DEV,
})
