const state = {
    shortlists: [],
    shortlistsFilter: [],
    shortlistsGroup: [],
    savedCreatorsCount: null,
}

const getters = {
    getShortlists: state => state.shortlists,
    getShortlistsGroup: state => state.shortlistsGroup,
    getFilterShortlist: state => state.shortlistsFilter,
    getSavedCreatorsCount: state => state.savedCreatorsCount,
}

const actions = {
    fetchShortlists: ({ commit }) => {
        const shortlists = [
            { id: 1, name: 'Shortlist 1', description: 'Description 1' },
            { id: 2, name: 'Shortlist 2', description: 'Description 2' },
        ]

        commit('setShortlists', shortlists)
    },
}

const mutations = {
    setShortlists: (state, shortlists) => {
        state.shortlists = shortlists
        state.shortlistsFilter = shortlists
    },
    setShortlistsGroup: (state, shortlistsGroup) => {
        state.shortlistsGroup = shortlistsGroup
    },
    setShortlistsFilter: (state, groupId) => {
        if (groupId === 0) {
            state.shortlistsFilter = state.shortlists
        } else {
            state.shortlistsFilter = state.shortlists.filter(item => item.group === groupId)
        }
    },
    addShortlist: (state, shortlist) => {
        state.shortlists.push(shortlist)
    },
    updateShortlist: (state, updatedShortlist) => {
        const index = state.shortlists.findIndex(c => c.id === updatedShortlist.id)
        if (index !== -1) {
            state.shortlists.splice(index, 1, updatedShortlist)
        }
    },
    deleteShortlist: (state, shortlistId) => {
        state.shortlists = state.shortlists.filter(c => c.id !== shortlistId)
    },
    setSavedCreatorsCount: (state, numberOfCreators) => {
        state.savedCreatorsCount = numberOfCreators
    },
    incrementSavedCreatorsCount: (state) => {
        state.savedCreatorsCount = state.savedCreatorsCount + 1
    },
    decrementSavedCreatorsCount: (state) => {
        state.savedCreatorsCount = state.savedCreatorsCount - 1
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
