/* eslint-disable no-undef */
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
    // Will be used by this service for making API calls
    axiosIns = null

    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    // For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // For Refreshing Token
    subscribers = []

    constructor(axiosIns, jwtOverrideConfig) {
        this.axiosIns = axiosIns
        this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

        // Request Interceptor
        this.axiosIns.interceptors.request.use(
            config => {
                const accessToken = this.getToken()
                const accessImpersonationToken = this.getImpersonationToken()

                // If token is present add it to request's Authorization Header
                if (accessToken) {
                    // eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                }
                if (accessImpersonationToken) {
                    // eslint-disable-next-line no-param-reassign
                    config.headers.ImpersonationAuthorization = `${this.jwtConfig.tokenType} ${accessImpersonationToken}`
                }
                if ($cookies.get('userData')) {
                    $cookies.set('userData', $cookies.get('userData'), '30min')
                    $cookies.set('accessToken', $cookies.get('accessToken'), '30min')
                    $cookies.set('refreshToken', $cookies.get('refreshToken'), '30min')
                }
                return config
            },
            error => Promise.reject(error),
        )

        // Add request/response interceptor
        this.axiosIns.interceptors.response.use(
            response => response,
            error => {
                // const { config, response: { status } } = error
                const { config, response } = error
                const originalRequest = config

                // if (status === 401) {
                if (response && response.status === 401) {
                    if (!this.isAlreadyFetchingAccessToken) {
                        this.isAlreadyFetchingAccessToken = true
                        this.refreshToken().then(r => {
                            this.isAlreadyFetchingAccessToken = false

                            this.setToken(r.data.accessToken)
                            this.setRefreshToken(r.data.refreshToken)
                            this.setImpersonationToken(r.data.accessImpersonationToken)

                            this.onAccessTokenFetched(r.data.accessToken)
                        })
                    }
                    const retryOriginalRequest = new Promise(resolve => {
                        this.addSubscriber(accessToken => {
                            // Make sure to assign accessToken according to your response.
                            // Check: https://pixinvent.ticksy.com/ticket/2413870
                            // Change Authorization header
                            originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                            resolve(this.axiosIns(originalRequest))
                        })
                    })
                    return retryOriginalRequest
                }
                return Promise.reject(error)
            },
        )
    }

    onAccessTokenFetched(accessToken) {
        this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    }

    addSubscriber(callback) {
        this.subscribers.push(callback)
    }

    getToken() {
        return $cookies.get(this.jwtConfig.storageTokenKeyName)
    }

    getImpersonationToken() {
        return $cookies.get(this.jwtConfig.storageImpersonationTokenKeyName)
    }

    getRefreshToken() {
        return $cookies.get(this.jwtConfig.storageRefreshTokenKeyName)
    }

    setToken(value) {
        $cookies.set(this.jwtConfig.storageTokenKeyName, value, '30min')
    }

    setImpersonationToken(value) {
        $cookies.set(this.jwtConfig.storageImpersonationTokenKeyName, value, '30min')
    }

    setRefreshToken(value) {
        $cookies.set(this.jwtConfig.storageRefreshTokenKeyName, value, '30min')
    }

    login(...args) {
        return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
    }

    register(...args) {
        return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
    }

    refreshToken() {
        return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
            refreshToken: this.getRefreshToken(),
        })
    }
}
