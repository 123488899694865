export default [
    {
        path: '/profile',
        name: 'apps-profile',
        props: { module: 'profile' },
        component: () => import('@/views/settings/UserProfile.vue'),
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Profile',
            redirectIfLoggedIn: false,
            module: 'profile',
        },
    },
    {
        path: '/my_profile',
        name: 'apps-profile-view',
        props: { module: 'profile' },
        component: () => import('@/views/profile/MarketplaceProfile.vue'),
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Profile',
            redirectIfLoggedIn: false,
            module: 'profile',
        },
    },
    {
        path: '/marketplace/:username/:platform?',
        name: 'apps-marketplace-profile-view',
        props: { module: 'profile' },
        component: () => import('@/views/profile/MarketplaceProfile.vue'),
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Profile',
            redirectIfLoggedIn: false,
            module: 'profile',
        },
    },
    {
        path: '/users/list',
        name: 'apps-users-list',
        props: { module: 'users' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Users',
            redirectIfLoggedIn: false,
            module: 'user',
        },
        component: () => import('@/views/user/UsersList.vue'),
    },
    {
        path: '/users/view/:id',
        name: 'apps-users-view',
        props: { module: 'users' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Users',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Users',
                    to: '/users/list',
                },
                {
                    text: 'action.View',
                    title: '',
                    active: true,
                },
            ],
            module: 'user',
        },
        component: () => import('@/views/user/UsersEdit.vue'),
    },
    {
        path: '/users/add',
        name: 'apps-users-add',
        props: { module: 'users' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Users',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Users',
                    to: '/users/list',
                },
                {
                    text: 'action.Add',
                    title: '',
                    active: true,
                },
            ],
            module: 'user',
        },
        component: () => import('@/views/user/UsersEdit.vue'),
    },
    {
        path: '/users/edit/:id',
        name: 'apps-users-edit',
        props: { module: 'users' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Users',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Users',
                    to: '/users/list',
                },
                {
                    text: 'action.Edit',
                    title: '',
                    active: true,
                },
            ],
            module: 'user',
        },
        component: () => import('@/views/user/UsersEdit.vue'),
    },
    // Role
    {
        path: '/roles/list',
        name: 'apps-roles-list',
        props: { module: 'roles' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Roles',
            redirectIfLoggedIn: false,
            module: 'role',
        },
        component: () => import('@/views/role/RolesList.vue'),
    },
    {
        path: '/roles/view/:id',
        name: 'apps-roles-view',
        props: { module: 'roles' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Roles',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Roles',
                    to: '/roles/list',
                },
                {
                    text: 'action.View',
                    title: '',
                    active: true,
                },
            ],
            module: 'role',
        },
        component: () => import('@/views/role/RolesEdit.vue'),
    },
    {
        path: '/roles/edit/:id',
        name: 'apps-roles-edit',
        props: { module: 'roles' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Roles',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Roles',
                    to: '/roles/list',
                },
                {
                    text: 'action.Edit',
                    title: '',
                    active: true,
                },
            ],
            module: 'role',
        },
        component: () => import('@/views/role/RolesEdit.vue'),
    },
    {
        path: '/roles/add',
        name: 'apps-roles-add',
        props: { module: 'roles' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Roles',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Roles',
                    to: '/roles/list',
                },
                {
                    text: 'action.Add',
                    title: '',
                    active: true,
                },
            ],
            module: 'role',
        },
        component: () => import('@/views/role/RolesEdit.vue'),
    },
    {
        path: '/brand/account-setup',
        name: 'apps-seller-account-setup',
        props: { module: 'seller-account-setup' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Account Setup',
            redirectIfLoggedIn: false,
            module: 'seller-account-setup',
        },
        component: () => import('@/views/settings/SellerAccountSetup.vue'),
    },
    {
        path: '/creator/account-setup',
        name: 'apps-creator-account-setup',
        props: { module: 'creator-account-setup' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Account Setup',
            redirectIfLoggedIn: false,
            module: 'creator-account-setup',
        },
        component: () => import('@/views/settings/InfluencerAccountSetup.vue'),
    },
    {
        path: '/brand/settings',
        name: 'apps-brand-settings',
        props: { module: 'settings' },
        component: () => import('@/views/settings/BrandSettings.vue'),
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Settings',
            redirectIfLoggedIn: false,
            module: 'settings',
        },
    },
    {
        path: '/settings',
        name: 'apps-creator-settings',
        props: { module: 'settings' },
        component: () => import('@/views/settings/CreatorSettings.vue'),
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Settings',
            redirectIfLoggedIn: false,
            module: 'settings',
        },
    },
    {
        path: '/choose-account',
        name: 'apps-accounts',
        props: { module: 'dashboard' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Choose Account',
            redirectIfLoggedIn: false,
            module: 'dashboard',
        },
        component: () => import('@/views/settings/ChooseAccount.vue'),
    },
    {
        path: '/creator-info',
        name: 'apps-creator-info',
        props: { module: 'dashboard' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Creator Information',
            redirectIfLoggedIn: false,
            module: 'dashboard',
        },
        component: () => import('@/views/settings/CreatorInfo.vue'),
    },
]
