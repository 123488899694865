/* eslint-disable no-undef */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'
import axios from '@axios'
import pages from './pages'
import store from '../store'
import communication from './communication'
import campaigns from './campaigns'
import auth from './auth'
import socialAuth from './socialAuth'
import dashboard from './dashboard'
import extenstion from './extenstion'
import others from './others'
import products from './products'
import settings from './settings'
import shortlinks from './shortlinks'
import shortlists from './shortlists'
import usersProfile from './usersProfile'
import orders from './orders'
import ai from './ai'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        { path: '/', redirect: { name: 'dashboard' } },
        ...auth,
        ...socialAuth,
        ...campaigns,
        ...communication,
        ...dashboard,
        ...extenstion,
        ...others,
        ...pages,
        ...products,
        ...settings,
        ...shortlinks,
        ...shortlists,
        ...usersProfile,
        ...orders,
        ...ai,
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})
Vue.prototype.$axios = axios
axios.defaults.baseURL = window.location.origin
Vue.prototype.$baseUrl = axios.defaults.baseURL

async function userSubscription() {
    const userSubscription = $cookies.get('userSubscription')
    if (userSubscription !== undefined && userSubscription !== null && userSubscription !== '') {
        return userSubscription
    }
    return axios.post('/api/user_subscription').then(response => {
        $cookies.set('userSubscription', JSON.stringify(response.data), '30m')
        return response.data
    })
}

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
    const isNameDiff = (to.name === from.name) ? 'N' : 'Y'
    if (isNameDiff === 'Y') {
        if (to.meta.layout === 'full' && to.meta.resource) {
            store.commit('appConfig/UPDATE_LAYOUT_TYPE', 'full')
        } else {
            store.commit('appConfig/UPDATE_LAYOUT_TYPE', 'vertical')
        }
        store.commit('appConfig/PERMISSIONS_DATA', [])
    }
    if (to.name === 'error-404' || to.name === 'not-authorized') {
        return next()
    }
    const isLoggedIn = isUserLoggedIn()
    const userData = $cookies.get('userData')

    if (to.name === 'apps-knowledge-base') {
        window.open('https://help.spliced.io/', '_blank')
        return next({ name: from.name })
    }

    if (!isLoggedIn || userData === null) {
        $cookies.remove('accessToken')
        $cookies.remove('refreshToken')
        $cookies.remove('userData')
        $cookies.remove('accessImpersonationToken')
        $cookies.remove('userSubscription')
        $cookies.remove('scouterMode')
        $cookies.remove('saved_query')

        localStorage.removeItem('columns_data')
        localStorage.removeItem('table_filters')
        localStorage.removeItem('campaignId')
        localStorage.removeItem('one_time_view_campaign_page')
        localStorage.removeItem('creators')
        localStorage.removeItem('network')
    }
    if (!isLoggedIn && to.meta.redirectIfLoggedIn === false) {
        // $cookies.set('login_error', 'Sorry, your session has expired. Please sign in and try again.', '1min')
        $cookies.set('from_page', { path: to.path, query: to.query }, '1m')

        const query = {}
        // eslint-disable-next-line guard-for-in
        for (const key in to.query) {
            query[key] = to.query[key]
        }

        $cookies.set('saved_query', JSON.stringify(query))

        if (to.name === 'auth-login') {
            return next()
        }
        return next({ name: 'auth-login' })
    }
    if (to.meta.resource === 'NoAuth' && isNameDiff === 'Y') {
        if (from.path !== to.path) {
            const fromPage = $cookies.get('from_page')
            $cookies.remove('from_page')
            // eslint-disable-next-line consistent-return
            userSubscription().then(response => {
                if (response.status === 'true') {
                    const permissionsArr = response.permissions
                    store.commit('appConfig/PERMISSIONS_DATA', permissionsArr)
                    if (response.data.is_client === 1) {
                        if (from.name === 'auth-login' && to.name !== 'apps-proposals-list') {
                            return next({ name: 'apps-proposals-list' })
                        }
                        return next()
                    }
                    if (permissionsArr[to.meta.module] !== undefined && permissionsArr[to.meta.module].includes('list')) {
                        if (response.data.is_choose_account === 'Y' && to.name !== 'apps-accounts') {
                            return next({ name: 'apps-accounts' })
                        }
                        if (to.name === 'apps-subscription') {
                            return next()
                        }
                        if (response.data.is_trial_expired === 'Y') {
                            if (to.name === 'apps-profile') {
                                return next()
                            }
                            if (to.name !== 'apps-subscription') {
                                return next({ name: 'apps-subscription' })
                            }
                        }
                        if (response.data.is_info_added === 'N' && to.name !== 'apps-creator-info' && to.name !== 'apps-creator-account-setup') {
                            return next({ name: 'apps-creator-info' })
                        }
                        if (response.data.is_seller_account_setup === 'Y' && to.name !== 'apps-seller-account-setup' && to.name !== 'apps-accounts') {
                            return next({ name: 'apps-seller-account-setup' })
                        } if (response.data.is_influencer_account_setup === 'Y' && to.name !== 'apps-creator-account-setup' && to.name !== 'apps-accounts') {
                            return next({ name: 'apps-creator-account-setup' })
                        }
                        if (response.data.is_seller_account_setup === 'N' && response.data.is_influencer_account_setup === 'N' && to.name !== 'apps-campaigns-products-view' && response.data.campaign_id !== '' && response.data.is_first_page === 'Y') {
                            const userSubscription = $cookies.get('userSubscription')
                            if (userSubscription.data.campaign_id !== undefined) {
                                userSubscription.data.campaign_id = ''
                                $cookies.set('userSubscription', JSON.stringify(userSubscription), '30m')
                            }
                            $cookies.set('isMPCampAlert', 'Y', '5m')
                            return next({ name: 'apps-campaigns-products-view', params: { id: response.data.campaign_id } })
                        }
                        if ((response.data.is_seller_account_setup === 'N' && to.name === 'apps-seller-account-setup') || (response.data.is_influencer_account_setup === 'N' && to.name === 'apps-creator-account-setup')) {
                            return next({ name: 'dashboard' })
                        }
                        if (fromPage !== undefined && fromPage !== null && fromPage !== '' && to.name !== from.name) {
                            return next({ path: fromPage.path, query: fromPage.query })
                        }
                        return next()
                    }
                    if (to.name !== 'dashboard') {
                        if (fromPage !== undefined && fromPage !== null && fromPage !== '' && to.name !== from.name) {
                            return next({ path: fromPage.path, query: fromPage.query })
                        }
                        return next({ name: 'dashboard' })
                    }
                } else {
                    $cookies.set('login_error', response.message, '1min')
                    $cookies.set('from_page', { path: to.path, query: to.query }, '1m')

                    $cookies.remove('accessToken')
                    $cookies.remove('refreshToken')
                    $cookies.remove('userData')
                    $cookies.remove('accessImpersonationToken')
                    $cookies.remove('userSubscription')
                    $cookies.remove('scouterMode')
                    $cookies.remove('saved_query')
                    $cookies.remove('login_success')

                    localStorage.removeItem('columns_data')
                    localStorage.removeItem('table_filters')
                    localStorage.removeItem('campaignId')
                    localStorage.removeItem('one_time_view_campaign_page')
                    localStorage.removeItem('creators')
                    localStorage.removeItem('network')

                    return next({ name: 'auth-login' })
                }
            })
        }
    } else {
        return next()
    }
})
export default router
