const state = {
    creators: [],
    tempCreators: [],
    filtered: false,
}

const getters = {
    getCreators: state => state.creators,
}

const actions = {
    fetchCreators: ({ commit }) => {
        const creators = [
            {
                id: 1,
                username: 'Creator 1',
            },
            {
                id: 2,
                username: 'Creator 2',
            },
        ]

        commit('setCreators', creators)
    },
}

const mutations = {
    setCreators: (state, creators) => {
        state.creators = creators
    },
    addCreator: (state, Creator) => {
        state.creators.push(Creator)
    },
    updateCreator: (state, updatedCreator) => {
        const index = state.creators.findIndex(c => c.id === updatedCreator.id)
        if (index !== -1) {
            state.creators.splice(index, 1, updatedCreator)
        }
    },
    deleteCreator: (state, creatorId) => {
        state.creators = state.creators.filter(c => c.id !== creatorId)
    },
    deleteAllCreators: state => {
        state.creators = []
    },
    filterCreator: (state, filters) => {
        if (!state.filtered) {
            state.tempCreators = state.creators
            state.filtered = true
        }

        const hasFilters = Object.values(filters).some(value => value !== '')

        if (!hasFilters) {
            state.creators = state.tempCreators
            state.filtered = false
        } else {
            state.creators = state.creators.filter(item => Object.entries(filters).every(([field, value]) => {
                if (field in item && item[field] !== '') {
                    return String(item[field]).includes(String(value))
                }
                return true
            }))
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
