import Vue from 'vue'
import { $themeConfig } from '@themeConfig'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

export default {
    namespaced: true,
    state: {
        layout: {
            isRTL: $themeConfig.layout.isRTL,
            // eslint-disable-next-line no-undef
            skin: $cookies.get('vuexy-skin') || $themeConfig.layout.skin,
            usertype: 1,
            routerTransition: $themeConfig.layout.routerTransition,
            type: $themeConfig.layout.type,
            contentWidth: $themeConfig.layout.contentWidth,
            showLoader: false,
            menu: {
                hidden: $themeConfig.layout.menu.hidden,
            },
            navbar: {
                type: $themeConfig.layout.navbar.type,
                backgroundColor: $themeConfig.layout.navbar.backgroundColor,
            },
            footer: {
                type: $themeConfig.layout.footer.type,
            },
        },
        updatePermissions: [],
        setDefaultStorefront: {},
        updateDefaultStorefront: 'N',
        setDefaultCreator: {},
        updateDefaultCreator: 'N',
        setDefaultClient: {},
        updateDefaultClient: 'N',
        updateWalletData: 'N',
        updateColumns: 'N',
        updateLeftMenu: 'N',
        updateScouterMode: 'N',
        updateNotifications: 'N',
        updateLocales: 'N',
        showToast: '',
    },
    getters: {},
    mutations: {
        TOGGLE_RTL(state) {
            state.layout.isRTL = !state.layout.isRTL
            document.documentElement.setAttribute('dir', state.layout.isRTL ? 'rtl' : 'ltr')
        },
        UPDATE_SKIN(state, skin) {
            state.layout.skin = skin

            // eslint-disable-next-line no-undef
            $cookies.set('vuexy-skin', skin, '30min')

            // Update DOM for dark-layout
            if (skin === 'dark') document.body.classList.add('dark-layout')
            else if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')
        },
        UPDATE_USERTYPE(state, val) {
            state.layout.usertype = val
        },
        UPDATE_ROUTER_TRANSITION(state, val) {
            state.layout.routerTransition = val
        },
        UPDATE_LAYOUT_TYPE(state, val) {
            state.layout.type = val
        },
        UPDATE_CONTENT_WIDTH(state, val) {
            state.layout.contentWidth = val
        },
        UPDATE_LOADER(state, val) {
            state.layout.showLoader = val
        },
        DEFAULT_STOREFRONT(state, val) {
            state.setDefaultStorefront = val
        },
        UPDATE_DEFAULT_STOREFRONT(state, val) {
            state.updateDefaultStorefront = val
        },
        PERMISSIONS_DATA(state, val) {
            state.updatePermissions = val
        },
        DEFAULT_CREATOR(state, val) {
            state.setDefaultCreator = val
        },
        UPDATE_DEFAULT_CREATOR(state, val) {
            state.updateDefaultCreator = val
        },
        DEFAULT_CLIENT(state, val) {
            state.setDefaultClient = val
        },
        UPDATE_DEFAULT_CLIENT(state, val) {
            state.updateDefaultClient = val
        },
        UPDATE_SCOUTER_MODE(state, val) {
            state.updateScouterMode = val
        },
        UPDATE_WALLET_DATA(state, val) {
            state.updateWalletData = val
        },
        UPDATE_COLUMNS(state, val) {
            state.updateColumns = val
        },
        SHOW_TOAST(state, val) {
            state.showToast = val
        },
        UPDATE_NAV_MENU_HIDDEN(state, val) {
            state.layout.menu.hidden = val
        },
        UPDATE_NAVBAR_CONFIG(state, obj) {
            Object.assign(state.layout.navbar, obj)
        },
        UPDATE_FOOTER_CONFIG(state, obj) {
            Object.assign(state.layout.footer, obj)
        },
        UPDATE_LEFT_MENU(state, val) {
            state.updateLeftMenu = val
        },
        UPDATE_NOTIFICATIONS(state, val) {
            state.updateNotifications = val
        },
        UPDATE_LOCALES(state, val) {
            state.updateLocales = val
        },
    },
    actions: {},
}
