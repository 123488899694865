export default [
    {
        path: '/chat/:id?',
        name: 'app-chat',
        props: { module: 'chat' },
        component: () => import('@/views/chat/ChatList.vue'),
        meta: {
            resource: 'NoAuth',
            redirectIfLoggedIn: false,
            contentRenderer: 'sidebar-left',
            contentClass: 'chat-application',
            module: 'chat',
        },
    },
    {
        path: '/email',
        name: 'apps-email',
        component: () => import('@/views/email/EmailList.vue'),
        props: { module: 'email' },
        meta: {
            resource: 'NoAuth',
            redirectIfLoggedIn: false,
            module: 'email',
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
        },
    },
    {
        path: '/email/:folder',
        name: 'apps-email-folder',
        component: () => import('@/views/email/EmailList.vue'),
        props: { module: 'email' },
        meta: {
            resource: 'NoAuth',
            redirectIfLoggedIn: false,
            module: 'email',
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            navActiveLink: 'apps-email',
        },
        beforeEnter(to, _, next) {
            if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
            else next({ name: 'error-404' })
        },
    },
    {
        path: '/email/label/:label',
        name: 'apps-email-label',
        component: () => import('@/views/email/EmailList.vue'),
        props: { module: 'email' },
        meta: {
            resource: 'NoAuth',
            module: 'email',
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            navActiveLink: 'apps-email',
        },
    },
]
