<template>
    <div>
        <sweet-modal
            ref="modal"
            :icon="icon"
            :title="title"
            :hide-close-button="hideCloseButton"
        >
            <component
                :is="component"
                :data="data"
            />
            <div
                v-if="text"
                v-html="text"
            />
        </sweet-modal>
    </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Modal',
    components: { 'sweet-modal': SweetModal },
    data() {
        return {
            icon: '',
            title: '',
            data: '',
            component: '',
            text: '',
            hideCloseButton: false,
        }
    },
    methods: {
        modal(data) {
            if (!data) {
                return this.$refs.modal
            } if (data === 'close') {
                return this.$refs.modal.close()
            } if (typeof data === 'object') {
                if (data.icon) {
                    this.icon = data.icon
                }
                if (data.title) {
                    this.title = data.title
                }
                if (data.hideCloseButton) {
                    this.hideCloseButton = data.hideCloseButton
                }
                if (data.text) {
                    this.text = data.text
                } else {
                    this.component = data.component
                    this.data = data.data
                    this.text = ''
                    this.hideCloseButton = data?.hideCloseButton
                }
            } else {
                this.component = false
                this.text = data
            }

            return this.$refs.modal.open()
        },
        open(data) {
            this.modal(data)
        },
    },
}
</script>

<style>

.sweet-modal {
    border-radius: 35px;
    max-width: 540px;
    overflow: hidden;
}

.sweet-modal:before {
    content: "";
    position: absolute;
    width: 291.92px;
    height: 145.17px;
    top: -150px;
    opacity: 0.7;
    -webkit-filter: blur(91px);
    filter: blur(91px);
    background: #4CB5ED;
    right: -100.66px;
    transform: rotate(-40.18deg);
}

.sweet-modal:after {
    content: "";
    position: absolute;
    width: 272.07px;
    height: 125px;
    bottom: -100px;
    opacity: 0.7;
    -webkit-filter: blur(91px);
    filter: blur(91px);
    background: #875AE2;
    left: -53.31px;
    right: -53.31px;
    transform: rotate(-22.43deg);
}

</style>
