export default [
    {
        path: '/campaigns/list',
        name: 'apps-campaigns-list',
        props: { module: 'campaigns' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Campaigns',
            redirectIfLoggedIn: false,
            module: 'campaigns',
        },
        component: () => import('@/views/campaigns/CampaignsList.vue'),
    },
    {
        path: '/campaigns/view/:id',
        name: 'apps-campaigns-view',
        props: { module: 'campaigns' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Campaigns',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Campaigns',
                    to: '/campaigns/list',
                },
                {
                    text: '',
                },
                {
                    text: 'action.View',
                    title: '',
                    active: true,
                },
            ],
            module: 'campaigns',
        },
        component: () => import('@/views/campaigns/CampaignsEdit.vue'),
    },
    {
        path: '/campaigns/add/:type',
        name: 'apps-campaigns-add',
        props: { module: 'campaigns' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Campaigns',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Campaigns',
                    to: '/campaigns/list',
                },
                {
                    text: '',
                },
                {
                    text: 'action.Add',
                    title: '',
                    active: true,
                },
            ],
            module: 'campaigns',
        },
        component: () => import('@/views/campaigns/CampaignsEdit.vue'),
    },
    {
        path: '/campaigns/edit/:id',
        name: 'apps-campaigns-edit',
        props: { module: 'campaigns' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Campaigns',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Campaigns',
                    to: '/campaigns/list',
                },
                {
                    text: '',
                },
                {
                    text: 'action.Edit',
                    title: '',
                    active: true,
                },
            ],
            module: 'campaigns',
        },
        component: () => import('@/views/campaigns/CampaignsEdit.vue'),
    },
]
