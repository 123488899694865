export default [
    {
        path: '/sample_upload',
        name: 'apps-sample-upload',
        props: { module: 'users' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Sample File Upload',
            redirectIfLoggedIn: false,
            module: 'user',
        },
        component: () => import('@/views/sample/SampleUpload.vue'),
    },
    {
        path: '/impersonate',
        name: 'apps-impersonate',
        props: { module: 'impersonate' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Impersonate',
            redirectIfLoggedIn: false,
            module: 'impersonate',
        },
        component: () => import('@/views/settings/UserImpersonate.vue'),
    },
    {
        path: '/payouts',
        name: 'apps-payouts',
        props: { module: 'payouts' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Payouts',
            redirectIfLoggedIn: false,
            module: 'payouts',
        },
        component: () => import('@/views/tools/PayoutTools.vue'),
    },
    {
        path: '/whitelist',
        name: 'apps-whitelist',
        props: { module: 'whitelist' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Whitelist',
            redirectIfLoggedIn: false,
            module: 'whitelist',
        },
        component: () => import('@/views/tools/WhiteList.vue'),
    },
]
