export default [
    {
        path: '/order/shipments',
        name: 'apps-shipment-orders',
        props: { module: 'shipment-orders' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Shipment Orders',
            redirectIfLoggedIn: false,
            module: 'orders',
        },
        component: () => import('@/views/orders/ShipmentList.vue'),
    },
]
