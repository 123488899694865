export default [
    {
        path: '/shortlists/list',
        name: 'apps-shortlists-list',
        props: { module: 'shortlists' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Shortlists',
            redirectIfLoggedIn: false,
            module: 'shortlists',
        },
        component: () => import('@/views/shortlists/ShortlistsList.vue'),
    },
    {
        path: '/shortlists-creator/list/:id',
        name: 'apps-shortlists-creator-list',
        props: { module: 'shortlists' },
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Shortlist Creators',
            redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: 'Shortlist Creators',
                    to: '/shortlists/list',
                },
                {
                    text: '',
                    active: true,
                },
            ],
            module: 'shortlists',
        },
        component: () => import('@/views/shortlists/ShortlistsCreatorList.vue'),
    },
]
