export default [
    {
        path: '/wallet',
        name: 'apps-wallet',
        props: { module: 'wallet' },
        component: () => import('@/views/wallet/WalletSettings.vue'),
        meta: {
            resource: 'NoAuth',
            pageTitle: 'Wallet & Payments',
            redirectIfLoggedIn: false,
            module: 'wallet',
        },
    },
    {
        path: '/subscription',
        name: 'apps-subscription',
        props: { module: 'subscription' },
        component: () => import('@/views/subscription/SubscriptionList.vue'),
        meta: {
            resource: 'NoAuth',
            redirectIfLoggedIn: false,
            module: 'subscription',
        },
    },
]
