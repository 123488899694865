import { computed, watch } from '@vue/composition-api'
import store from '@/store'

export default function usAppConfig() {
    const isVerticalMenuCollapsed = computed({
        get: () => store.state.verticalMenu.isVerticalMenuCollapsed,
        set: val => {
            store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', val)
        },
    })

    const isRTL = computed({
        get: () => store.state.appConfig.layout.isRTL,
        set: val => {
            store.commit('appConfig/TOGGLE_RTL', val)
        },
    })

    const skin = computed({
        get: () => store.state.appConfig.layout.skin,
        set: val => {
            store.commit('appConfig/UPDATE_SKIN', val)
        },
    })

    const usertype = computed({
        get: () => store.state.appConfig.layout.usertype,
        set: val => {
            store.commit('appConfig/UPDATE_USERTYPE', val)
        },
    })

    const skinClasses = computed(() => {
        if (skin.value === 'bordered') return 'bordered-layout'
        if (skin.value === 'semi-dark') return 'semi-dark-layout'
        return null
    })

    const routerTransition = computed({
        get: () => store.state.appConfig.layout.routerTransition,
        set: val => {
            store.commit('appConfig/UPDATE_ROUTER_TRANSITION', val)
        },
    })

    const layoutType = computed({
        get: () => store.state.appConfig.layout.type,
        set: val => {
            store.commit('appConfig/UPDATE_LAYOUT_TYPE', val)
        },
    })

    watch(layoutType, val => {
        if (val === 'horizontal' && skin.value === 'semi-dark') skin.value = 'light'
    })

    const contentWidth = computed({
        get: () => store.state.appConfig.layout.contentWidth,
        set: val => {
            store.commit('appConfig/UPDATE_CONTENT_WIDTH', val)
        },
    })

    const showLoader = computed({
        get: () => store.state.appConfig.layout.showLoader,
        set: val => {
            store.commit('appConfig/UPDATE_LOADER', val)
        },
    })

    const setDefaultStorefront = computed({
        get: () => store.state.appConfig.setDefaultStorefront,
        set: val => {
            store.commit('appConfig/DEFAULT_STOREFRONT', val)
        },
    })

    const setDefaultCreator = computed({
        get: () => store.state.appConfig.setDefaultCreator,
        set: val => {
            store.commit('appConfig/DEFAULT_CREATOR', val)
        },
    })

    const setDefaultClient = computed({
        get: () => store.state.appConfig.setDefaultClient,
        set: val => {
            store.commit('appConfig/DEFAULT_CLIENT', val)
        },
    })

    const updateDefaultStorefront = computed({
        get: () => store.state.appConfig.updateDefaultStorefront,
        set: val => {
            store.commit('appConfig/UPDATE_DEFAULT_STOREFRONT', val)
        },
    })

    const updateDefaultCreator = computed({
        get: () => store.state.appConfig.updateDefaultCreator,
        set: val => {
            store.commit('appConfig/UPDATE_DEFAULT_CREATOR', val)
        },
    })

    const updateDefaultClient = computed({
        get: () => store.state.appConfig.updateDefaultClient,
        set: val => {
            store.commit('appConfig/UPDATE_DEFAULT_CLIENT', val)
        },
    })

    const updateScouterMode = computed({
        get: () => store.state.appConfig.updateScouterMode,
        set: val => {
            store.commit('appConfig/UPDATE_SCOUTER_MODE', val)
        },
    })

    const updateWalletData = computed({
        get: () => store.state.appConfig.updateWalletData,
        set: val => {
            store.commit('appConfig/UPDATE_WALLET_DATA', val)
        },
    })

    const updateColumns = computed({
        get: () => store.state.appConfig.updateColumns,
        set: val => {
            store.commit('appConfig/UPDATE_COLUMNS', val)
        },
    })

    const showToast = computed({
        get: () => store.state.appConfig.showToast,
        set: val => {
            store.commit('appConfig/SHOW_TOAST', val)
        },
    })

    const updatePermissions = computed({
        get: () => store.state.appConfig.updatePermissions,
        set: val => {
            store.commit('appConfig/PERMISSIONS_DATA', val)
        },
    })

    const isNavMenuHidden = computed({
        get: () => store.state.appConfig.layout.menu.hidden,
        set: val => {
            store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', val)
        },
    })

    const navbarBackgroundColor = computed({
        get: () => store.state.appConfig.layout.navbar.backgroundColor,
        set: val => {
            store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { backgroundColor: val })
        },
    })

    const navbarType = computed({
        get: () => store.state.appConfig.layout.navbar.type,
        set: val => {
            store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: val })
        },
    })

    const footerType = computed({
        get: () => store.state.appConfig.layout.footer.type,
        set: val => {
            store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: val })
        },
    })

    const updateLeftMenu = computed({
        get: () => store.state.appConfig.updateLeftMenu,
        set: val => {
            store.commit('appConfig/UPDATE_LEFT_MENU', val)
        },
    })

    const updateNotifications = computed({
        get: () => store.state.appConfig.updateNotifications,
        set: val => {
            store.commit('appConfig/UPDATE_NOTIFICATIONS', val)
        },
    })

    const updateLocales = computed({
        get: () => store.state.appConfig.updateLocales,
        set: val => {
            store.commit('appConfig/UPDATE_LOCALES', val)
        },
    })

    return {
        isVerticalMenuCollapsed,
        isRTL,
        skin,
        skinClasses,
        usertype,
        routerTransition,
        navbarBackgroundColor,
        navbarType,
        footerType,
        layoutType,
        contentWidth,
        showLoader,
        isNavMenuHidden,
        setDefaultStorefront,
        setDefaultCreator,
        setDefaultClient,
        updateDefaultStorefront,
        updateDefaultCreator,
        updateDefaultClient,
        updateWalletData,
        updatePermissions,
        updateColumns,
        updateLeftMenu,
        updateScouterMode,
        updateNotifications,
        updateLocales,
        showToast,
    }
}
