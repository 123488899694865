export default [
    {
        path: '/oauth/amazon',
        name: 'apps-amazon-signup',
        props: { module: 'auth' },
        component: () => import('@/views/authentication/SocialMediaAuthorize.vue'),
        meta: {
            resource: 'Auth',
            layout: 'full',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/oauth/google',
        name: 'apps-google-signup',
        props: { module: 'auth' },
        component: () => import('@/views/authentication/SocialMediaAuthorize.vue'),
        meta: {
            resource: 'Auth',
            layout: 'full',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/oauth/tiktok',
        name: 'apps-tiktok-signup',
        props: { module: 'auth' },
        component: () => import('@/views/authentication/SocialMediaAuthorize.vue'),
        meta: {
            resource: 'Auth',
            layout: 'full',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/oauth/tiktok/connect',
        name: 'apps-tiktok-connect',
        props: { module: 'auth' },
        component: () => import('@/views/authentication/SocialMediaAuthorize.vue'),
        meta: {
            resource: 'Auth',
            layout: 'full',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/oauth/amazon/setup',
        name: 'apps-amazon-account-setup',
        props: { module: 'auth' },
        component: () => import('@/views/authentication/SocialMediaAuthorize.vue'),
        meta: {
            resource: 'Auth',
            layout: 'full',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/oauth/instagram/connect',
        name: 'apps-instagram-connect',
        props: { module: 'auth' },
        component: () => import('@/views/authentication/SocialMediaAuthorize.vue'),
        meta: {
            resource: 'Auth',
            layout: 'full',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/oauth/google/connect',
        name: 'apps-gmail-connect',
        props: { module: 'auth' },
        component: () => import('@/views/authentication/SocialMediaAuthorize.vue'),
        meta: {
            resource: 'Auth',
            layout: 'full',
            redirectIfLoggedIn: true,
        },
    },
]
