const state = {
    fetchFullProfile: false,
}

const getters = {
    getFetchFullProfile: state => state.fetchFullProfile,
}

const mutations = {
    setFetchFullProfile: (state, fetchFullProfile) => {
        state.fetchFullProfile = fetchFullProfile
    },
}

export default {
    state,
    getters,
    mutations,
}
