export default [
    {
        path: '/extension/signin',
        name: 'ext-auth-login',
        props: { module: 'auth' },
        component: () => import('@/views/extension/Login.vue'),
        meta: {
            resource: 'Auth',
            layout: 'full',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/extension/creator/add/:id?',
        name: 'ext-creator-add',
        props: { module: 'extension' },
        component: () => import('@/views/extension/AddCreator.vue'),
        meta: {
            resource: 'NoAuth',
            layout: 'full',
            redirectIfLoggedIn: true,
            module: 'shortlists',
        },
    },
    {
        path: '/extension/shortlist/add',
        name: 'ext-shortlist-add',
        props: { module: 'extension' },
        component: () => import('@/views/extension/AddShortlist.vue'),
        meta: {
            resource: 'NoAuth',
            layout: 'full',
            redirectIfLoggedIn: true,
            module: 'shortlists',
        },
    },
    {
        path: '/extension/settings',
        name: 'ext-settings',
        props: { module: 'extension' },
        component: () => import('@/views/extension/Settings.vue'),
        meta: {
            resource: 'NoAuth',
            layout: 'full',
            redirectIfLoggedIn: true,
            module: 'shortlists',
        },
    },
]
