const state = {
    campaigns: [],
}

const getters = {
    getCampaigns: state => state.campaigns,
}

const actions = {
    fetchCampaigns: ({ commit }) => {
        const campaigns = [
            { id: 1, name: 'Campaign 1', description: 'Description 1' },
            { id: 2, name: 'Campaign 2', description: 'Description 2' },
        ]

        commit('setCampaigns', campaigns)
    },
}

const mutations = {
    setCampaigns: (state, campaigns) => {
        state.campaigns = campaigns
    },
    addCampaign: (state, campaign) => {
        state.campaigns.push(campaign)
    },
    updateCampaign: (state, updatedCampaign) => {
        const index = state.campaigns.findIndex(c => c.id === updatedCampaign.id)
        if (index !== -1) {
            state.campaigns.splice(index, 1, updatedCampaign)
        }
    },
    deleteCampaign: (state, campaignId) => {
        state.campaigns = state.campaigns.filter(c => c.id !== campaignId)
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
