<!-- eslint-disable no-undef -->
<template>
    <vue-element-loading
        v-show="isLoading"
        active
    >
        <div class="main_animation_container">
            <svg
                id="spliced"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="280.06px"
                height="102.72px"
                viewBox="0 0 280.06 102.72"
                enable-background="new 0 0 280.06 102.72"
                xml:space="preserve"
                style="opacity: 1;"
            >
                <g
                    id="logoAll"
                    data-svg-origin="140.04000616073608 52.71000289916992"
                    transform="matrix(1,0,0,1,0,0)"
                    style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px; opacity: 1;"
                >
                    <g id="word2">
                        <g
                            id="ePB"
                            data-svg-origin="182.27000427246094 48.150001525878906"
                            transform="matrix(1,0,0,1,0,-0.5919)"
                            style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
                        >
                            <linearGradient
                                id="eP_1_"
                                gradientUnits="userSpaceOnUse"
                                x1="181.6935"
                                y1="61.5231"
                                x2="224.0718"
                                y2="61.5231"
                            >
                                <stop
                                    offset="0"
                                    style="stop-color:#8898E1"
                                />
                                <stop
                                    offset="1"
                                    style="stop-color:#E741C9"
                                />
                            </linearGradient>
                            <path
                                id="eP"
                                fill="none"
                                stroke="url(#eP_1_)"
                                stroke-linecap="round"
                                stroke-miterlimit="10"
                                d="M202.57,63.98
               c-2.52,0-4.66-0.62-6.41-1.85c-1.75-1.23-2.88-2.91-3.4-5.03h27.75c-2.88-3.35-5.6-6.52-8.35-8.95h-18.41
               c-3.59,3.36-7.31,8.15-11.48,12.86c0.4,0.98,0.87,1.95,1.42,2.88c1.98,3.38,4.66,6.07,8.04,8.04c3.38,1.98,7.11,2.97,11.18,2.97
               c4.47,0,8.55-1.23,12.22-3.7c3.67-2.47,6.39-5.68,8.17-9.63l-13.33-0.09c-0.92,0.75-1.94,1.35-3.05,1.81
               C205.8,63.75,204.35,63.98,202.57,63.98L202.57,63.98z"
                                style="stroke-dashoffset: 35px; stroke-dasharray: 163;"
                            />
                        </g>
                        <g
                            id="cPB"
                            data-svg-origin="132.88002014160156 14"
                            transform="matrix(1,0,0,1,0,0)"
                            style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
                        >
                            <linearGradient
                                id="cP_1_"
                                gradientUnits="userSpaceOnUse"
                                x1="132.3916"
                                y1="44.4476"
                                x2="274.1252"
                                y2="44.4476"
                            >
                                <stop
                                    offset="0"
                                    style="stop-color:#8898E1"
                                />
                                <stop
                                    offset="1"
                                    style="stop-color:#E741C9"
                                />
                            </linearGradient>
                            <path
                                id="cP"
                                fill="none"
                                stroke="url(#cP_1_)"
                                stroke-linecap="round"
                                stroke-miterlimit="10"
                                d="M153.01,63.79
               c-2.22-0.39-4.14-1.4-5.76-3.04c-2.12-2.15-3.18-4.83-3.18-8.04c0-3.21,1.06-5.89,3.18-8.04c2.12-2.15,4.76-3.23,7.91-3.23
               c2.01,0,3.83,0.46,5.46,1.38c1.64,0.92,2.94,2.18,3.91,3.78h9.97c0.47-0.52,0.94-1.04,1.41-1.56c-1.43-4-3.89-7.34-7.37-10.01
               c-3.93-3.01-8.42-4.52-13.46-4.52c-4.01,0-7.73,0.99-11.14,2.97c-3.41,1.98-6.11,4.67-8.09,8.09c-1.98,3.41-2.97,7.13-2.97,11.14
               c0,4.01,0.99,7.73,2.97,11.14c1.98,3.41,4.67,6.11,8.09,8.09c2.79,1.62,5.78,2.57,8.97,2.87c0.71,0.07,1.43,0.1,2.17,0.1
               c3.78-0.03,7.21-0.96,10.37-2.5c6.19-3.02,11.34-8.36,16.03-13.77c5.71-6.59,10.75-13.27,16.18-15.97
               c0.11-0.06,0.22-0.12,0.34-0.17c0.66-0.32,1.35-0.57,2.06-0.74c0-0.01,0.01-0.01,0.02,0c0.16-0.04,0.31-0.08,0.47-0.11
               c0.78-0.16,1.57-0.23,2.37-0.21c1.93,0,3.73,0.48,5.41,1.44c13.19,6.5,19.67,26.46,37.01,31.16c1.95,0.53,4.01,0.83,6.19,0.86
               c0.73,0,1.45-0.03,2.17-0.1c3.19-0.3,6.16-1.25,8.93-2.87c3.38-1.98,6.06-4.67,8.04-8.09c1.98-3.41,2.97-7.13,2.97-11.14V14
               h-11.27v19.78c-3.04-2.18-6.65-3.27-10.84-3.27c-4.01,0-7.71,0.99-11.1,2.97c-3.38,1.98-6.08,4.67-8.09,8.09
               c-0.65,1.11-1.2,2.25-1.64,3.42c3.63,3.99,6.98,7.99,10.24,11.08c-0.29-1.05-0.43-2.17-0.43-3.37c0-3.21,1.03-5.89,3.1-8.04
               c2.06-2.15,4.67-3.23,7.83-3.23c3.15,0,5.79,1.08,7.91,3.23c2.12,2.15,3.18,4.83,3.18,8.04c0,3.21-1.06,5.89-3.18,8.04
               c-1.62,1.64-3.53,2.65-5.75,3.04c-0.69,0.12-1.42,0.18-2.17,0.18c-2.56,0-4.75-0.71-6.59-2.12c-4.95-2.99-9.71-8.71-14.89-14.45
               c-4.78-5.31-9.91-10.64-15.86-13.86l0,0l-0.01,0c-0.3-0.17-0.61-0.33-0.93-0.49c-3.38-1.69-6.91-2.54-10.58-2.54
               c-4.01,0-7.7,1.02-11.05,3.05c-8.99,5.46-23.84,31.59-36.34,30.41C154.42,63.98,153.7,63.91,153.01,63.79L153.01,63.79z
                M214.08,33.54L214.08,33.54L214.08,33.54L214.08,33.54z"
                                style="stroke-dashoffset: 0px; stroke-dasharray: 613;"
                            />
                        </g>
                        <g
                            id="iP1B"
                            data-svg-origin="115.94999694824219 31.239999771118164"
                            transform="matrix(1,0,0,1,0,0)"
                            style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
                        >
                            <linearGradient
                                id="iP1_1_"
                                gradientUnits="userSpaceOnUse"
                                x1="114.3302"
                                y1="52.7429"
                                x2="128.6638"
                                y2="52.7429"
                            >
                                <stop
                                    offset="0"
                                    style="stop-color:#69B4EA"
                                />
                                <stop
                                    offset="0.2106"
                                    style="stop-color:#6CAAE8"
                                />
                                <stop
                                    offset="0.2598"
                                    style="stop-color:#6DA7E8"
                                />
                                <stop
                                    offset="0.4291"
                                    style="stop-color:#6F9EE7"
                                />
                                <stop
                                    offset="0.7041"
                                    style="stop-color:#7686E3"
                                />
                                <stop
                                    offset="1"
                                    style="stop-color:#7F65DD"
                                />
                            </linearGradient>

                            <rect
                                id="iP1"
                                x="115.95"
                                y="31.24"
                                fill="none"
                                stroke="url(#iP1_1_)"
                                stroke-linecap="round"
                                stroke-miterlimit="10"
                                width="11.18"
                                height="43.01"
                                style="stroke-dashoffset: 0px; stroke-dasharray: 109;"
                            />
                        </g>
                        <g
                            id="iP2SX"
                            data-svg-origin="121.49999618530273 25.37150764465332"
                            transform="matrix(1,0,0,1,0,0)"
                            style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
                        >
                            <g
                                id="iP2SY"
                                data-svg-origin="121.49999618530273 32.029799461364746"
                                transform="matrix(1,0,0,1,0,0)"
                                style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
                            >
                                <linearGradient
                                    id="iP2_1_"
                                    gradientUnits="userSpaceOnUse"
                                    x1="114.3301"
                                    y1="20.0112"
                                    x2="128.6638"
                                    y2="20.0112"
                                >
                                    <stop
                                        offset="0"
                                        style="stop-color:#69B4EA"
                                    />
                                    <stop
                                        offset="0.2106"
                                        style="stop-color:#6CAAE8"
                                    />
                                    <stop
                                        offset="0.2598"
                                        style="stop-color:#6DA7E8"
                                    />
                                    <stop
                                        offset="0.4291"
                                        style="stop-color:#6F9EE7"
                                    />
                                    <stop
                                        offset="0.7041"
                                        style="stop-color:#7686E3"
                                    />
                                    <stop
                                        offset="1"
                                        style="stop-color:#7F65DD"
                                    />
                                </linearGradient>
                                <path
                                    id="iP2"
                                    fill="none"
                                    stroke="url(#iP2_1_)"
                                    stroke-linecap="round"
                                    stroke-miterlimit="10"
                                    d="M121.54,26.68
                   c1.84,0,3.4-0.64,4.69-1.94c1.29-1.29,1.94-2.88,1.94-4.77c0-1.83-0.65-3.4-1.94-4.69c-1.29-1.29-2.85-1.94-4.69-1.94
                   c-1.89,0-3.48,0.65-4.77,1.94c-1.29,1.29-1.94,2.85-1.94,4.69c0,1.89,0.65,3.48,1.94,4.77
                   C118.06,26.03,119.65,26.68,121.54,26.68z"
                                    data-svg-origin="114.82999420166016 13.339998245239258"
                                    transform="matrix(1,0,0,1,0,-12)"
                                    style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px; stroke-dashoffset: 39px; stroke-dasharray: 43;"
                                />
                            </g>
                        </g>
                        <g
                            id="lPB"
                            data-svg-origin="96.76000213623047 14"
                            transform="matrix(1,0,0,1,0,0)"
                            style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
                        >
                            <linearGradient
                                id="lP_1_"
                                gradientUnits="userSpaceOnUse"
                                x1="96.2624"
                                y1="44.1035"
                                x2="108.4453"
                                y2="44.1035"
                            >
                                <stop
                                    offset="0.1369"
                                    style="stop-color:#6AB1E9"
                                />
                                <stop
                                    offset="1"
                                    style="stop-color:#69B4EA"
                                />
                            </linearGradient>
                            <polygon
                                id="lP"
                                fill="none"
                                stroke="url(#lP_1_)"
                                stroke-linecap="round"
                                stroke-miterlimit="10"
                                points="96.76,74.21 96.76,14 107.95,14 107.95,74.21 96.76,74.21"
                                style="stroke-dashoffset: 0px; stroke-dasharray: 143;"
                            />
                        </g>
                        <g
                            id="pPB"
                            data-svg-origin="6.440000057220459 30.495262145996094"
                            transform="matrix(1,0,0,1,0,0)"
                            style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
                        >
                            <linearGradient
                                id="pP_1_"
                                gradientUnits="userSpaceOnUse"
                                x1="5.9389"
                                y1="60.9617"
                                x2="92.531"
                                y2="60.9617"
                            >
                                <stop
                                    offset="0"
                                    style="stop-color:#7F65DD"
                                />
                                <stop
                                    offset="1"
                                    style="stop-color:#69B4EA"
                                />
                            </linearGradient>
                            <path
                                id="pP"
                                fill="none"
                                stroke="url(#pP_1_)"
                                stroke-linecap="round"
                                stroke-miterlimit="10"
                                d="M27.25,74.83
               c0.74-0.05,1.46-0.12,2.16-0.21c2.41-0.32,4.56-0.92,6.46-1.78c1.33-0.66,2.59-1.47,3.79-2.4c2.96-2.29,5.59-5.31,8.07-8.53
               c5.31-6.9,9.93-14.74,15.6-18.39c1.81-1.34,3.94-2.04,6.38-2.07c0.07,0,0.14,0,0.22,0c0.75,0,1.47,0.06,2.16,0.18
               c2.2,0.39,4.09,1.4,5.66,3.04c2.06,2.15,3.1,4.83,3.1,8.04c0,3.21-1.03,5.89-3.1,8.04c-2.06,2.15-4.67,3.23-7.83,3.23
               c-3.15,0-5.79-1.08-7.91-3.23c-2.12-2.15-3.18-4.83-3.18-8.04c0-0.79,0.06-1.55,0.19-2.28c-3.63,4.31-7.18,9.97-11.29,14.95
               v26.04H59V71.63c3.04,2.18,6.65,3.27,10.84,3.27c4.01,0,7.71-0.99,11.1-2.97c3.38-1.98,6.08-4.67,8.09-8.09
               c2.01-3.41,3.01-7.13,3.01-11.14c0-4.01-1-7.73-3.01-11.14c-2.01-3.41-4.7-6.11-8.09-8.09c-2.77-1.62-5.75-2.57-8.93-2.87
               c-0.71-0.07-1.43-0.1-2.16-0.1c-14.17-0.29-22.16,12.81-29.37,22.9c-4.11,5.75-7.97,10.52-12.59,11.28
               c-0.65,0.13-1.37,0.2-2.16,0.22c-0.17,0.01-0.35,0.01-0.53,0.01c-2.35,0-4.29-0.43-5.81-1.29c-1.52-0.86-2.28-1.98-2.28-3.35
               H6.44c0,3.1,0.85,5.75,2.54,7.96c1.69,2.21,3.94,3.87,6.75,4.99c2.81,1.12,5.88,1.68,9.2,1.68C25.73,74.9,26.5,74.88,27.25,74.83
               L27.25,74.83z"
                                style="stroke-dashoffset: 0px; stroke-dasharray: 378;"
                            />
                        </g>
                        <g
                            id="sPB"
                            data-svg-origin="6.870002746582031 30.5000057220459"
                            transform="matrix(1,0,0,1,0,0)"
                            style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
                        >
                            <linearGradient
                                id="sP_1_"
                                gradientUnits="userSpaceOnUse"
                                x1="6.3689"
                                y1="45.5073"
                                x2="42.7241"
                                y2="45.5073"
                            >
                                <stop
                                    offset="0"
                                    style="stop-color:#7F65DD"
                                />
                                <stop
                                    offset="0.2098"
                                    style="stop-color:#7E6ADE"
                                />
                                <stop
                                    offset="0.4455"
                                    style="stop-color:#7A77E0"
                                />
                                <stop
                                    offset="0.6937"
                                    style="stop-color:#748EE4"
                                />
                                <stop
                                    offset="0.949"
                                    style="stop-color:#6BADE9"
                                />
                                <stop
                                    offset="1"
                                    style="stop-color:#69B4EA"
                                />
                            </linearGradient>
                            <path
                                id="sP"
                                fill="none"
                                stroke="url(#sP_1_)"
                                stroke-linecap="round"
                                stroke-miterlimit="10"
                                d="M32.16,60.08
               c2.2-1.93,4.36-4.8,6.65-8.04c-1.22-0.86-2.54-1.51-3.98-1.96c-2.29-0.72-5.13-1.36-8.52-1.94c-2.87-0.46-4.96-0.95-6.28-1.46
               c-1.32-0.52-1.98-1.29-1.98-2.32c0-1.15,0.62-2.09,1.85-2.84c1.23-0.75,2.77-1.12,4.6-1.12c1.89,0,3.54,0.4,4.95,1.2
               c1.41,0.8,2.11,1.81,2.11,3.01h10.67c0-4.42-1.63-7.87-4.9-10.37c-3.27-2.5-7.48-3.74-12.65-3.74c-3.33,0-6.34,0.56-9.03,1.68
               c-2.7,1.12-4.83,2.67-6.41,4.65c-1.58,1.98-2.37,4.2-2.37,6.67c0,3.21,0.82,5.69,2.45,7.44c1.64,1.75,3.57,2.98,5.81,3.7
               c2.24,0.72,5.05,1.36,8.43,1.94c3.04,0.52,5.25,1.03,6.62,1.55C31.28,58.54,31.94,59.19,32.16,60.08L32.16,60.08z"
                                style="stroke-dashoffset: 0px; stroke-dasharray: 145;"
                            />
                        </g>
                    </g>
                    <g id="word1">
                        <linearGradient
                            id="eF_1_"
                            gradientUnits="userSpaceOnUse"
                            x1="182.2726"
                            y1="61.5231"
                            x2="223.3012"
                            y2="61.5231"
                            style="opacity: 0;"
                        >
                            <stop
                                offset="0"
                                style="stop-color:#8898E1"
                            />
                            <stop
                                offset="1"
                                style="stop-color:#E741C9"
                            />
                        </linearGradient>
                        <path
                            id="eF"
                            fill="url(#eF_1_)"
                            d="M202.57,63.98c-2.52,0-4.66-0.62-6.41-1.85c-1.75-1.23-2.88-2.91-3.4-5.03h27.75
           c-2.88-3.35-5.6-6.52-8.35-8.95h-18.41c-3.59,3.36-7.31,8.15-11.48,12.86c0.4,0.98,0.87,1.95,1.42,2.88
           c1.98,3.38,4.66,6.07,8.04,8.04c3.38,1.98,7.11,2.97,11.18,2.97c4.47,0,8.55-1.23,12.22-3.7c3.67-2.47,6.39-5.68,8.17-9.63
           l-13.33-0.09c-0.92,0.75-1.94,1.35-3.05,1.81C205.8,63.75,204.35,63.98,202.57,63.98L202.57,63.98z"
                            style="opacity: 0;"
                        />
                        <linearGradient
                            id="cF_1_"
                            gradientUnits="userSpaceOnUse"
                            x1="132.8916"
                            y1="44.4476"
                            x2="273.6252"
                            y2="44.4476"
                            style="opacity: 0;"
                        >
                            <stop
                                offset="0"
                                style="stop-color:#8898E1"
                            />
                            <stop
                                offset="1"
                                style="stop-color:#E741C9"
                            />
                        </linearGradient>
                        <path
                            id="cF"
                            fill="url(#cF_1_)"
                            d="M153.01,63.79c-2.22-0.39-4.14-1.4-5.76-3.04c-2.12-2.15-3.18-4.83-3.18-8.04
           c0-3.21,1.06-5.89,3.18-8.04c2.12-2.15,4.76-3.23,7.91-3.23c2.01,0,3.83,0.46,5.46,1.38c1.64,0.92,2.94,2.18,3.91,3.78h9.97
           c0.47-0.52,0.94-1.04,1.41-1.56c-1.43-4-3.89-7.34-7.37-10.01c-3.93-3.01-8.42-4.52-13.46-4.52c-4.01,0-7.73,0.99-11.14,2.97
           c-3.41,1.98-6.11,4.67-8.09,8.09c-1.98,3.41-2.97,7.13-2.97,11.14c0,4.01,0.99,7.73,2.97,11.14c1.98,3.41,4.67,6.11,8.09,8.09
           c2.79,1.62,5.78,2.57,8.97,2.87c0.71,0.07,1.43,0.1,2.17,0.1c3.78-0.03,7.21-0.96,10.37-2.5c6.19-3.02,11.34-8.36,16.03-13.77
           c5.71-6.59,10.75-13.27,16.18-15.97c0.11-0.06,0.22-0.12,0.34-0.17c0.66-0.32,1.35-0.57,2.06-0.74c0-0.01,0.01-0.01,0.02,0
           c0.16-0.04,0.31-0.08,0.47-0.11c0.78-0.16,1.57-0.23,2.37-0.21c1.93,0,3.73,0.48,5.41,1.44c13.19,6.5,19.67,26.46,37.01,31.16
           c1.95,0.53,4.01,0.83,6.19,0.86c0.73,0,1.45-0.03,2.17-0.1c3.19-0.3,6.16-1.25,8.93-2.87c3.38-1.98,6.06-4.67,8.04-8.09
           c1.98-3.41,2.97-7.13,2.97-11.14V14h-11.27v19.78c-3.04-2.18-6.65-3.27-10.84-3.27c-4.01,0-7.71,0.99-11.1,2.97
           c-3.38,1.98-6.08,4.67-8.09,8.09c-0.65,1.11-1.2,2.25-1.64,3.42c3.63,3.99,6.98,7.99,10.24,11.08c-0.29-1.05-0.43-2.17-0.43-3.37
           c0-3.21,1.03-5.89,3.1-8.04c2.06-2.15,4.67-3.23,7.83-3.23c3.15,0,5.79,1.08,7.91,3.23c2.12,2.15,3.18,4.83,3.18,8.04
           c0,3.21-1.06,5.89-3.18,8.04c-1.62,1.64-3.53,2.65-5.75,3.04c-0.69,0.12-1.42,0.18-2.17,0.18c-2.56,0-4.75-0.71-6.59-2.12
           c-4.95-2.99-9.71-8.71-14.89-14.45c-4.78-5.31-9.91-10.64-15.86-13.86l0,0l-0.01,0c-0.3-0.17-0.61-0.33-0.93-0.49
           c-3.38-1.69-6.91-2.54-10.58-2.54c-4.01,0-7.7,1.02-11.05,3.05c-8.99,5.46-23.84,31.59-36.34,30.41
           C154.42,63.98,153.7,63.91,153.01,63.79L153.01,63.79z M214.08,33.54L214.08,33.54L214.08,33.54L214.08,33.54z"
                            style="opacity: 0;"
                        />
                        <linearGradient
                            id="iF1_1_"
                            gradientUnits="userSpaceOnUse"
                            x1="115.8754"
                            y1="52.7429"
                            x2="129.209"
                            y2="52.7429"
                            style="opacity: 0;"
                        >
                            <stop
                                offset="0"
                                style="stop-color:#69B4EA"
                            />
                            <stop
                                offset="0.2106"
                                style="stop-color:#6CAAE8"
                            />
                            <stop
                                offset="0.2598"
                                style="stop-color:#6DA7E8"
                            />
                            <stop
                                offset="0.4291"
                                style="stop-color:#6F9EE7"
                            />
                            <stop
                                offset="0.7041"
                                style="stop-color:#7686E3"
                            />
                            <stop
                                offset="1"
                                style="stop-color:#7F65DD"
                            />
                        </linearGradient>
                        <rect
                            id="iF1"
                            x="115.95"
                            y="31.24"
                            fill="url(#iF1_1_)"
                            width="11.18"
                            height="43.01"
                            style="opacity: 0;"
                        />
                        <g
                            id="iF2SX"
                            data-svg-origin="121.49999618530273 25.37150764465332"
                            transform="matrix(1,0,0,1,0,0)"
                            style="opacity: 0; translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
                        >
                            <g
                                id="iF2SY"
                                data-svg-origin="121.49999618530273 32.029799461364746"
                                transform="matrix(1,0,0,1,0,0)"
                                style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
                            >
                                <linearGradient
                                    id="iF2_1_"
                                    gradientUnits="userSpaceOnUse"
                                    x1="115.8754"
                                    y1="20.0112"
                                    x2="129.209"
                                    y2="20.0112"
                                >
                                    <stop
                                        offset="0"
                                        style="stop-color:#69B4EA"
                                    />
                                    <stop
                                        offset="0.2106"
                                        style="stop-color:#6CAAE8"
                                    />
                                    <stop
                                        offset="0.2598"
                                        style="stop-color:#6DA7E8"
                                    />
                                    <stop
                                        offset="0.4291"
                                        style="stop-color:#6F9EE7"
                                    />
                                    <stop
                                        offset="0.7041"
                                        style="stop-color:#7686E3"
                                    />
                                    <stop
                                        offset="1"
                                        style="stop-color:#7F65DD"
                                    />
                                </linearGradient>
                                <path
                                    id="iF2"
                                    fill="url(#iF2_1_)"
                                    d="M121.54,26.68c1.84,0,3.4-0.64,4.69-1.94c1.29-1.29,1.94-2.88,1.94-4.77
                   c0-1.83-0.65-3.4-1.94-4.69c-1.29-1.29-2.85-1.94-4.69-1.94c-1.89,0-3.48,0.65-4.77,1.94c-1.29,1.29-1.94,2.85-1.94,4.69
                   c0,1.89,0.65,3.48,1.94,4.77C118.06,26.03,119.65,26.68,121.54,26.68z"
                                    data-svg-origin="114.82999420166016 13.339998245239258"
                                    transform="matrix(1,0,0,1,0,-12)"
                                    style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
                                />
                            </g>
                        </g>
                        <linearGradient
                            id="lF_1_"
                            gradientUnits="userSpaceOnUse"
                            x1="97.639"
                            y1="44.1035"
                            x2="108.8219"
                            y2="44.1035"
                            style="opacity: 0;"
                        >
                            <stop
                                offset="0.1369"
                                style="stop-color:#6AB1E9"
                            />
                            <stop
                                offset="1"
                                style="stop-color:#69B4EA"
                            />
                        </linearGradient>
                        <polygon
                            id="lF"
                            fill="url(#lF_1_)"
                            points="96.76,74.21 96.76,14 107.95,14 107.95,74.21 96.76,74.21 "
                            style="opacity: 0;"
                        />
                        <linearGradient
                            id="pF_1_"
                            gradientUnits="userSpaceOnUse"
                            x1="6.4389"
                            y1="60.9617"
                            x2="92.031"
                            y2="60.9617"
                            style="opacity: 0;"
                        >
                            <stop
                                offset="0"
                                style="stop-color:#7F65DD"
                            />
                            <stop
                                offset="1"
                                style="stop-color:#69B4EA"
                            />
                        </linearGradient>
                        <path
                            id="pF"
                            fill="url(#pF_1_)"
                            d="M27.25,74.83c0.74-0.05,1.46-0.12,2.16-0.21c2.41-0.32,4.56-0.92,6.46-1.78
           c1.33-0.66,2.59-1.47,3.79-2.4c2.96-2.29,5.59-5.31,8.07-8.53c5.31-6.9,9.93-14.74,15.6-18.39c1.81-1.34,3.94-2.04,6.38-2.07
           c0.07,0,0.14,0,0.22,0c0.75,0,1.47,0.06,2.16,0.18c2.2,0.39,4.09,1.4,5.66,3.04c2.06,2.15,3.1,4.83,3.1,8.04
           c0,3.21-1.03,5.89-3.1,8.04c-2.06,2.15-4.67,3.23-7.83,3.23c-3.15,0-5.79-1.08-7.91-3.23c-2.12-2.15-3.18-4.83-3.18-8.04
           c0-0.79,0.06-1.55,0.19-2.28c-3.63,4.31-7.18,9.97-11.29,14.95v26.04H59V71.63c3.04,2.18,6.65,3.27,10.84,3.27
           c4.01,0,7.71-0.99,11.1-2.97c3.38-1.98,6.08-4.67,8.09-8.09c2.01-3.41,3.01-7.13,3.01-11.14c0-4.01-1-7.73-3.01-11.14
           c-2.01-3.41-4.7-6.11-8.09-8.09c-2.77-1.62-5.75-2.57-8.93-2.87c-0.71-0.07-1.43-0.1-2.16-0.1c-14.17-0.29-22.16,12.81-29.37,22.9
           c-4.11,5.75-7.97,10.52-12.59,11.28c-0.65,0.13-1.37,0.2-2.16,0.22c-0.17,0.01-0.35,0.01-0.53,0.01c-2.35,0-4.29-0.43-5.81-1.29
           c-1.52-0.86-2.28-1.98-2.28-3.35H6.44c0,3.1,0.85,5.75,2.54,7.96c1.69,2.21,3.94,3.87,6.75,4.99c2.81,1.12,5.88,1.68,9.2,1.68
           C25.73,74.9,26.5,74.88,27.25,74.83L27.25,74.83z"
                            style="opacity: 0;"
                        />
                        <linearGradient
                            id="sF_1_"
                            gradientUnits="userSpaceOnUse"
                            x1="9.6403"
                            y1="45.2977"
                            x2="44.9954"
                            y2="45.2977"
                            style="opacity: 0;"
                        >
                            <stop
                                offset="0"
                                style="stop-color:#7F65DD"
                            />
                            <stop
                                offset="0.2098"
                                style="stop-color:#7E6ADE"
                            />
                            <stop
                                offset="0.4455"
                                style="stop-color:#7A77E0"
                            />
                            <stop
                                offset="0.6937"
                                style="stop-color:#748EE4"
                            />
                            <stop
                                offset="0.949"
                                style="stop-color:#6BADE9"
                            />
                            <stop
                                offset="1"
                                style="stop-color:#69B4EA"
                            />
                        </linearGradient>
                        <path
                            id="sF"
                            fill="url(#sF_1_)"
                            d="M32.16,60.08c2.2-1.93,4.36-4.8,6.65-8.04c-1.22-0.86-2.54-1.51-3.98-1.96
           c-2.29-0.72-5.13-1.36-8.52-1.94c-2.87-0.46-4.96-0.95-6.28-1.46c-1.32-0.52-1.98-1.29-1.98-2.32c0-1.15,0.62-2.09,1.85-2.84
           c1.23-0.75,2.77-1.12,4.6-1.12c1.89,0,3.54,0.4,4.95,1.2c1.41,0.8,2.11,1.81,2.11,3.01h10.67c0-4.42-1.63-7.87-4.9-10.37
           c-3.27-2.5-7.48-3.74-12.65-3.74c-3.33,0-6.34,0.56-9.03,1.68c-2.7,1.12-4.83,2.67-6.41,4.65c-1.58,1.98-2.37,4.2-2.37,6.67
           c0,3.21,0.82,5.69,2.45,7.44c1.64,1.75,3.57,2.98,5.81,3.7c2.24,0.72,5.05,1.36,8.43,1.94c3.04,0.52,5.25,1.03,6.62,1.55
           C31.28,58.54,31.94,59.19,32.16,60.08L32.16,60.08z"
                            style="opacity: 0;"
                        />
                    </g>
                </g>
            </svg>

        </div>
    </vue-element-loading>
</template>
<script>
// eslint-disable-next-line import/extensions
import './gsap.min.js'

export default {
    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    mounted() {
        this.applyGP()
    },
    methods: {
        applyGP() {
            gsap.set('.main_animation_container svg', { opacity: 1 })
            gsap.set('#word1 > *', { opacity: 0 })
            gsap.set('#eP', { strokeDasharray: 163, strokeDashoffset: 163 })
            gsap.set('#cP', { strokeDasharray: 613, strokeDashoffset: 613 })
            gsap.set('#iP1', { strokeDasharray: 109, strokeDashoffset: 109 })
            gsap.set('#iP2', { y: -12, strokeDasharray: 43, strokeDashoffset: 43 })
            gsap.set('#lP', { strokeDasharray: 143, strokeDashoffset: 143 })
            gsap.set('#pP', { strokeDasharray: 378, strokeDashoffset: 378 })
            gsap.set('#sP', { strokeDasharray: 145, strokeDashoffset: 145 })
            gsap.set('#ePB', { y: 12 })
            gsap.set('#cPB', { y: -12 })
            gsap.set('#iP1B', { y: 12 })
            gsap.set('#lPB', { y: -12 })
            gsap.set('#pPB', { y: 12 })
            gsap.set('#sPB', { y: -12 })
            gsap.set('#iF2', { y: -12 })
            gsap.timeline({ repeat: -1, defaults: { ease: 'sine.inOut', duration: 0.5 } })
                .to('#sP', { strokeDashoffset: 0 })
                .to('#sPB', { y: 0, ease: 'back.out(1.2)' }, '<')
                .to('#pP', { strokeDashoffset: 0 }, '>-.25')
                .to('#pPB', { y: 0, ease: 'back.out(1.2)' }, '<')
                .to('#lP', { strokeDashoffset: 0 }, '>-.25')
                .to('#lPB', { y: 0, ease: 'back.out(1.2)' }, '<')
                .to('#iP1', { strokeDashoffset: 0 }, '>-.25')
                .to('#iP1B', { y: 0, ease: 'back.out(1.2)' }, '<')
                .to('#cP', { strokeDashoffset: 0 }, '>-.25')
                .to('#cPB', { y: 0, ease: 'back.out(1.2)' }, '<')
                .to('#eP', { strokeDashoffset: 0 }, '>-.25')
                .to('#ePB', { y: 0, ease: 'back.out(1.2)' }, '<')
                .to('#iP2', { strokeDashoffset: 0 }, '>-.25')
                .to('#iP2', { y: 14 }, '>-.3')
                .to('#iF2', { y: 14 }, '<')
                .to('#iF2SY, #iP2SY', { scaleY: 0.7, transformOrigin: 'center' }, '<')
                .to('#iF2SX, #iP2SX', { scaleX: 1.2, transformOrigin: 'center' }, '<')
                .to('#iP2', { y: 0 }, '>-.1')
                .to('#iF2', { y: 0 }, '<')
                .to('#iF2SY, #iP2SY', { scaleY: 1, transformOrigin: 'center' }, '<')
                .to('#iF2SX, #iP2SX', { scaleX: 1, transformOrigin: 'center' }, '<')
                .to('#word1 > *', { opacity: 1 }, '<')
                .to('#logoAll', {
                    scale: 1.05, opacity: 0, transformOrigin: 'center', ease: 'back.in(1.5)', duration: 1,
                }, '>-.25')
        },
    },
}
</script>
<style>
    .main_animation_container {
        display: flex;
        position: relative;
        height: 95vh;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .main_animation_container svg {
        width: 25%;
        height: 100vh;
        opacity: 0;
    }
</style>
